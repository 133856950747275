


import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GIFT_TYPE, GIFT_SOURCE_TYPE } from "@/constants";
import QuestionnaireMixin from "@/mixins/questionnaire.mixin";
import { mixins } from "vue-class-component";
import {
  AdditionalLoans,
  Gift
} from "../../../../models/pos/loan-and-property.model";
import AddressComponent from "@/views/LosAddress.vue";
import Axios from "axios";
import { BASE_API_URL } from "../../../../config";


@Component({ components: { AddressComponent } })
export default class PersonalInformationComponent extends mixins(
  QuestionnaireMixin
) {
  @Prop()
  public history;
  public giftType = GIFT_TYPE;
  public giftSourceType = GIFT_SOURCE_TYPE;
  public loanAndProperty = null;
  public dotLoader = false;

  public initializeArray(type) {
    let initTypes = {
      addlLoan: new AdditionalLoans(),
      gifts: new Gift()
    };

    return initTypes[type];
  }

  /**
   * An asynchronous method to re-initialise fields of type string/object/array.
   * @param key Data property which is to be re-initialised.
   */
  public async clearFilledData(key: string) {
    const i = this.questionHistory.index;
    switch (key) {
      case "MoreThanOneNewMortgage":
        if (this.loanAndProperty.propertyDetail.moreThanOneMortgage == "true")
          return;
        this.loanAndProperty.propertyDetail.additionalLoans = [
          new AdditionalLoans()
        ];
        this.loanAndProperty.propertyDetail.expectedRentalIncome = 0;
        break;

      case "RentalIncome":
        if (this.loanAndProperty.propertyDetail.generateRentalIncome == "true")
          return;
        this.loanAndProperty.propertyDetail.expectedRentalIncome = 0;
        break;

      case "ReceivedGifts":
        if (this.loanAndProperty.propertyDetail.receiveGift == "true") return;
        this.loanAndProperty.propertyDetail.gifts = [new Gift()];
        break;
    }
  }

  public async getLoanAndPropertyDetail() {
    try {
      this.dotLoader = true;
      let res = await Axios.get(BASE_API_URL + "pos/getLoanAndPropertyDetail", {
        params: {
          loanTxnId: this.$route.query.loanTxnId
        }
      });
      this.loanAndProperty = res.data;
      this.dotLoader = false;
    } catch (error) {
      this.dotLoader = false;
    }
  }

  public getHelocFromCreditLimit(additionalLoans){
    return additionalLoans.map(additionalLoan => {
      additionalLoan.isHeloc = false;
      if (additionalLoan.creditLimit > 0) {
        additionalLoan.isHeloc = true;
      }
      return additionalLoan;
    });
  }

  public async saveLoanAndPropertyDetail(nextQuestion?: number, currentQuestion?: number, scope?: string, nextSection?: string, userType?:string, index?:number) {
    try {
      let valid = await this.$validator.validateAll(scope);
      this.loanAndProperty.propertyDetail.additionalLoans = this.getHelocFromCreditLimit(this.loanAndProperty.propertyDetail.additionalLoans);
      if (!valid) {
          return;
        };
      
      this.$store.state.wemloLoader = true;
      let res = await Axios.post(
        BASE_API_URL + "pos/saveLoanAndPropertyDetail",
        {
        loanAndProperty: this.loanAndProperty.propertyDetail,
        });
      this.$store.state.wemloLoader = false;
      this.$snotify.clear();
      this.$snotify.success("Information saved successfully", {
        timeout: 1000
      });
     this.onNext(nextQuestion, currentQuestion, scope, nextSection, userType, index);
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
      this.$snotify.error(
        "We are unable to save your details. Please try contacting the administrator."
      );
    }
  }

  isRetired(data){
    if(data){
      return data.currentEmployment[0].isRetired;
    }
  }

  async mounted() {
    this.questionHistory = this.history
    await this.getLoanAndPropertyDetail();
  }
}
